
import { defineComponent, h } from "vue";
import { ElDivider } from "element-plus";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import {
  literalCode6,
  spacerCode6
} from "@/views/resources/documentation/element-ui/basic/space/code";

export default defineComponent({
  name: "spacer",
  data() {
    return {
      size: 10,
      spacer: h(ElDivider, { direction: "vertical" })
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      literalCode6,
      spacerCode6
    };
  }
});
